@import url('https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200..800;1,200..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');
@import url('https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css');
:root {
  --green: #2ab370;
  --flogreen: rgb(87, 225, 64);
  --darkgreen: #017a00;
  --grey: #555;
  --lightgrey: #ecebeb;
  --accent-color: var(--green);
  --custom-header-font: Nunito Sans;
  --custom-main-font: Karla;
  --breadcrumbs-background: #ecebeb;
  --link-foreground-color: var(--green);
  --theme-low-contrast-foreground-color: var(--green);
  --theme-foreground-color: var(--darkgreen);
  --narrow-container-width: var(--default-container-width) !important;
}
