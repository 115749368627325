// Intentionally empty
// Used for customizations in other add-ons
@import 'variables';
//General
html {
  scroll-behavior: smooth;
}
a {
  color: var(--green);
  text-decoration: none !important;
}
.view-viewview .container.tags {
  display: none;
}
//Navigation
.mobile-nav {
  z-index: 1000;
}
.scroll-watcher {
  position: fixed;
  z-index: 1000;
  top: 0;
  width: 100%;
  height: 10px;
  animation: scroll-watcher linear;
  animation-timeline: scroll();
  background-color: var(--flogreen);
  scale: 0 1;
  transform-origin: left;
}
@keyframes scroll-watcher {
  to {
    scale: 1 1;
  }
}
#navigation .hamburger-wrapper {
  display: none !important;
}
.navigation ul.desktop-menu > li > button,
.navigation ul.desktop-menu > li > a {
  font-family: var(--custom-main-font, sans-serif) !important;
  font-weight: 300 !important;
}
.ui.secondary.pointing.menu .active.item {
  border-color: var(--darkgreen);
}
.ui.secondary.pointing.menu .active.item:hover,
.ui.secondary.pointing.menu .item:hover {
  border-color: var(--green);
}
.navigation .ui.secondary.pointing.menu .item,
.tools .ui.secondary.pointing.menu .item {
  text-transform: initial;
}
@media only screen and (min-width: 940px) {
  .navigation .ui.secondary.pointing.menu .item,
  .tools .ui.secondary.pointing.menu .item {
    margin-right: 1.4em;
  }
}
@media only screen and (max-width: 1440px) and (min-width: 941px) {
  .logo-nav-wrapper {
    flex-wrap: wrap;
  }
  .logo-nav-wrapper div {
    flex: 1 1 100%; /* Default size for all boxes */
  }
  .logo-nav-wrapper .logo,
  .logo-nav-wrapper .search-wrapper {
    flex: 1 1 50%; /* First and third boxes take 50% of the row */
  }
  .logo-nav-wrapper nav {
    order: 1; /* Move the second box to the next row */
    margin-top: 20px;
  }
}
.header-wrapper .header .search-wrapper .search button:hover {
  background-color: var(--green);
}
.introHeader {
  background: var(--green);
  color: #fff;
  font-size: 16px;
}
.introHeader .ui.grid > .column:not(.row) {
  padding-bottom: 0;
}
.introHeader span {
  display: inline-block;
  padding: 10px 20px;
  border-right: 1px solid rgba(255, 255, 255, 0.5);
}
.introHeader span:last-child {
  border-right: none;
}
.introHeader span:first-child {
  padding-left: 0;
}
.introHeader a {
  color: #fff;
  font-size: 16px;
}
.introHeader .ui.horizontal.list > .item {
  padding-top: 0;
  padding-bottom: 0;
}
//Slider
.block.slider .teaser-item .teaser-item-title button {
  border: 1px solid #fff;
  color: #fff;
}
.block.slider .teaser-item .teaser-item-title button:hover {
  background: #fff;
  color: var(--green);
}
.blocks-group-wrapper:has(.block.slider) {
  padding-bottom: 0;
}
.block.slider {
  margin-bottom: 0 !important;
}
//Slide Carousel
.slick-slide p {
  color: #000;
  font-size: 16px;
  line-height: initial;
}
.block.carousel h2.headline {
  padding-top: 2em;
  padding-bottom: 0.5em;
  margin-right: 8px;
  margin-bottom: 0;
  margin-left: 8px;
  font-weight: 600;
}
.block.carousel .grid-image-wrapper img {
  position: initial;
  border-radius: 7px;
  aspect-ratio: initial;
}
.block.carousel .grid-image-wrapper {
  padding-top: 0;
}
.block.carousel h3 {
  margin-top: 0;
}
.slick-dots {
  bottom: -50px;
}
//Columns Teasers
body .block.gridBlock .block.teaser {
  overflow: hidden;
  border: 4px solid #fff;
  border-radius: 12px !important;
  box-shadow: 0px 3px 10px -4px #000;
}
body .block.gridBlock .block.teaser .teaser-item {
  background: var(--green);
}
body .block.gridBlock .column:nth-child(even) .block.teaser .teaser-item {
  background: var(--darkgreen);
}
body .block.teaser h2,
body .block.teaser p,
body .block.teaser .headline {
  color: #fff;
  text-align: center;
}
body .blocks-group-wrapper.grey .block.teaser h2,
body .blocks-group-wrapper.grey .block.teaser p,
body .blocks-group-wrapper.grey .block.teaser .headline {
  color: #000;
}
body .block.teaser p {
  font-size: 16px;
  line-height: initial;
}
body .block.gridBlock .block.teaser p:last-child {
  margin-bottom: 0;
}
.block.gridBlock .block.teaser .teaser-item .image-wrapper,
#page-edit
  .block-editor-gridBlock
  .block.gridBlock
  .block.teaser
  .teaser-item
  .image-wrapper,
#page-add
  .block-editor-gridBlock
  .block.gridBlock
  .block.teaser
  .teaser-item
  .image-wrapper {
  margin-bottom: 20px;
}
.siteroot .blocks-group-wrapper:has(.block.gridBlock) {
  padding-top: 0;
  padding-bottom: 0;
  margin-top: -40px;
  background-color: transparent !important;
}
.default .block.gridBlock {
  position: relative;
  background: transparent !important;
}
//Footer
#footer-main {
  font-size: 16px;
}
#footer-main h4 {
  margin-bottom: 8px;
}
#footer-main a {
  color: #fff;
  font-size: 16px;
}
#footer-main .item {
  margin-bottom: 5px;
}
.below-footer {
  margin: 10px 0;
  color: #ccc;
  font-size: 14px;
}
.below-footer a {
  color: #ccc;
  font-size: 14px;
}
#footer-main hr {
  margin: 30px 0;
}
//Breadcrumbs
#main .breadcrumbs {
  padding: 10px 0;
}
body #main > .breadcrumbs a.section,
#main .breadcrumbs .breadcrumb .divider::before {
  color: #000 !important;
}
body #main > .breadcrumbs a.home svg {
  fill: #000 !important;
}
#main .breadcrumbs .breadcrumb .section.active {
  display: none !important;
}
//Accordion
body .accordion-block .ui.styled.accordion,
body .block-editor-accordion .ui.styled.accordion {
  border-top: 0;
}
.accordion-block .ui.styled.accordion:last-child,
.block-editor-accordion .ui.styled.accordion:last-child {
  border-bottom: 0;
}
body .accordion-block .ui.styled.accordion .title.accordion-title,
body .block-editor-accordion .ui.styled.accordion .title.accordion-title {
  padding: 15px;
  border-top: 1px solid var(--flogreen);
  border-bottom: 1px solid var(--darkgreen);
  background: var(--green);
}
body .accordion-block .ui.styled.accordion:first-child .title.accordion-title,
body
  .block-editor-accordion
  .ui.styled.accordion:first-child
  .title.accordion-title {
  border-radius: 12px 12px 0 0;
}
body .accordion-block .ui.styled.accordion:last-child .title.accordion-title,
body
  .block-editor-accordion
  .ui.styled.accordion:last-child
  .title.accordion-title {
  border-radius: 0 0 12px 12px;
}
body .accordion-block .ui.styled.accordion .title.accordion-title > span,
body .block-editor-accordion .ui.styled.accordion .title.accordion-title > span,
body .accordion-block .ui.styled.accordion .title.accordion-title.active > span,
body
  .block-editor-accordion
  .ui.styled.accordion
  .title.accordion-title.active
  > span {
  color: #fff;
}
body .accordion-block .ui.styled.accordion .title.accordion-title > svg,
body .block-editor-accordion .ui.styled.accordion .title.accordion-title > svg,
body .accordion-block .ui.styled.accordion .title.accordion-title.active > svg,
body
  .block-editor-accordion
  .ui.styled.accordion
  .title.accordion-title.active
  > svg {
  fill: #fff !important;
}
body .accordion-block .ui.styled.accordion .content {
  margin-top: 30px;
}
//Search block
.block.listing.summary .listing-item img,
.column>.block.listing.summary .listing-item img,
.block.search.summary .listing-item img,
.block-editor-search.summary .listing-item img {
  aspect-ratio: initial !important;
}

#page-add .block.search .search-input-resultscount-sort,
#page-document .block.search .search-input-resultscount-sort,
#page-edit .block.search .search-input-resultscount-sort {
  background: var(--green);
}

#page-document .block.search .search-wrapper,
#page-add .block.search .search-wrapper,
#page-edit .block.search .search-wrapper {
  margin: 10px;
}

body .block.search .search-details h2,
body .block.search .search-details span {
  color: #fff;
}
#page-document .block.search .search-details-sort,
#page-add .block.search .search-details-sort,
#page-edit .block.search .search-details-sort {
  padding: 0 10px;
  background-color: var(--darkgreen);
}
#page-document .block.search .search-details-sort h2 nobr span {
  font-size: 16px !important;
  font-weight: 300;
}
body .block.search .search-input {
  border-radius: 12px;
}
#page-document .block.search .search-input-resultscount-sort,
#page-add .block.search .search-input-resultscount-sort,
#page-edit .block.search .search-input-resultscount-sort {
  margin-bottom: 40px;
}
.block.listing .pagination-wrapper .ui.pagination.menu,
.column > .block.listing .pagination-wrapper .ui.pagination.menu,
.block.search .pagination-wrapper .ui.pagination.menu,
.block-editor-search .pagination-wrapper .ui.pagination.menu {
  margin-bottom: 0;
}
body .block.listing .pagination-wrapper .total,
body .block.search .pagination-wrapper .total {
  margin-top: 10px;
  color: #ccc;
  font-size: 16px;
}
#page-document .block.search .listing-item h2,
#page-document .block.search .listing-item h3,
#page-add .block.search .listing-item h2,
#page-add .block.search .listing-item h3,
#page-edit .block.search .listing-item h2,
#page-edit .block.search .listing-item h3 {
  font-size: 24px;
  font-weight: 300;
  line-height: 28px;
}
.block.search .listing-body p.authors {
  margin-bottom: 5px;
}
.block.search .listing-body p.authors,
.block.search .listing-body p.effective-date {
  font-size: 16px;
}
#page-document .block.search .search-wrapper,
#page-add .block.search .search-wrapper,
#page-edit .block.search .search-wrapper {
  width: 100%;
  padding: 10px;
  margin: 0;
}
//General
#page-document .blocks-group-wrapper.default a {
  --theme-foreground-color: var(--darkgreen) !important;
  --theme-low-contrast-foreground-color: var(--green) !important;
}
.siteroot .documentFirstHeading {
  display: none;
}
h1,
h2,
h3,
h4,
h5,
h1.documentFirstHeading {
  font-family: var(--custom-header-font, sans-serif) !important;
  font-weight: 800;
}
h1.documentFirstHeading {
  margin-bottom: 40px;
}
.documentDescription {
  max-width: var(--default-container-width) !important;
}
.contenttype-news-item #page-document .dates {
  margin-bottom: 0;
}
.contenttype-news-item h1.documentFirstHeading {
  padding-top: 10px;
}
.block.image figcaption,
.block.gridBlock .block.image figure::after,
#page-edit .block-editor-gridBlock .block.gridBlock .block.image figure::after,
#page-add .block-editor-gridBlock .block.gridBlock .block.image figure::after {
  display: none;
}
.block.__button {
  max-width: var(--narrow-container-width) !important;
}
.block.image figure img {
  width: auto !important;
  max-width: 100% !important;
}
.block.image figure.center img {
  margin-right: auto;
  margin-left: auto;
}
.block.html {
  min-width: var(--default-container-width) !important;
  max-width: var(--default-container-width) !important;
}
.eb-widget-event-list {
  min-height: 500px;
}
@media only screen and (min-width: 940px) {
  //Search block
  /*Search bits*/
  #page-document
    .block.search
    .searchBlock-container
    .stackable.grid.searchBlock-facets
    .row:first-child {
    width: 30% !important;
    flex-grow: 1;
    padding-left: 20px !important;
  }
  #page-document .block.search .search-input-resultscount-sort,
  #page-add .block.search .search-input-resultscount-sort,
  #page-edit .block.search .search-input-resultscount-sort {
    flex-direction: column;
    margin-bottom: 20px;
  }
  #page-document .block.search .search-details,
  #page-add .block.search .search-details,
  #page-edit .block.search .search-details {
    padding: 0;
  }
  #page-document .block.search .search-details .number,
  #page-add .block.search .search-details .number,
  #page-edit .block.search .search-details .number {
    font-size: initial;
  }
  #page-document .block.search .facets,
  #page-add .block.search .facets,
  #page-edit .block.search .facets {
    margin-top: 0;
  }
  #page-document .block.search .facets .column,
  #page-document .block.search .listing.message .column,
  #page-add .block.search .facets .column,
  #page-add .block.search .listing.message .column,
  #page-edit .block.search .facets .column,
  #page-edit .block.search .listing.message .column {
    width: 100% !important;
    padding: 0;
    margin: 0 0 20px;
  }
  /*Listing bits*/
  #page-document
    .block.search
    .searchBlock-container
    .stackable.grid.searchBlock-facets
    .row:last-child {
    width: 70% !important;
    flex-grow: 2;
    order: -1;
  }
  .detailed.listing-item a img {
    flex: 0 0 25%;
  }
  .listing-item img {
    width: 25%;
  }
}
@media only screen and (max-width: 940px) {
  .introHeader {
    display: none;
  }
}
